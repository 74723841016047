<template>
  <div class="filters-color">
    <h4 v-if="title" class="title">Filter by {{ title }}</h4>
    <div class="colors">
      <div :class="$t(`color ${color.isActive ? 'active' : ''}`)" v-for="color in filterColors" v-bind:key="color.value" @click="onClick(color.value)">
        <div v-if="color.color" class="circle" :style="{ backgroundColor: color.color }"></div>
        <div v-if="color.icon" class="icon">
          <IconChecked v-if="color.icon === 'checked'" />
          <IconNotChecked v-if="color.icon === 'not-checked'" />
        </div>
        <span>{{ color.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import IconChecked from "../../components/ui/icons/IconChecked";
  import IconNotChecked from "../../components/ui/icons/IconNotChecked";
  export default {
    name: "FiltersByColor",
    components: { IconNotChecked, IconChecked },
    props: {
      colors: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: () => "",
      },
      callback: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        activeColor: null,
        filterColors: this.colors,
      };
    },
    methods: {
      onClick(value) {
        this.activeColor = this.activeColor === value ? "" : value;
        this.filterColors = this.colors.map(color => {
          color.isActive = color.value === this.activeColor;
          return color;
        });

        this.$emit("callback", this.activeColor);
      },
    },
  };
</script>

<style lang="scss">
  .filters-color {
    margin: 24px 0 32px;
    position: relative;
    z-index: 49;

    .title {
      font-size: 14px;
      line-height: 18px;
      text-transform: none;
      font-weight: 500;
    }

    .icon {
      margin-right: 4px;

      svg {
        display: block;
        width: 10px;
        height: 10px;
      }
    }

    .colors {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .color {
        border-radius: 20px;
        border: 1px solid #d6dee2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px 20px;
        margin-top: 20px;
        margin-right: 16px;
        cursor: pointer;
        transition: 0.26s border-color;
        white-space: nowrap;

        .circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }

        span {
          font-size: 14px;
          line-height: 18px;
        }

        &:hover {
          border: 2px solid #8d9ca8;
          padding: 10px 19px;
        }

        &.active {
          border: 2px solid #000000;
          padding: 10px 19px;
        }
      }
    }
  }
</style>
