<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip093712)">
      <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#32BA7C"/>
      <path d="M5.95605 11.6088L10.0696 15.7224C13.4766 14.8139 16.0002 11.7097 16.0002 7.99997C16.0002 7.92426 16.0002 7.84855 16.0002 7.77284L12.7699 4.79492L5.95605 11.6088Z" fill="#0AA06E"/>
      <path d="M8.2017 9.79214C8.55501 10.1455 8.55501 10.7511 8.2017 11.1044L7.46984 11.8363C7.11652 12.1896 6.51085 12.1896 6.15753 11.8363L2.95248 8.60602C2.59917 8.25271 2.59917 7.64703 2.95248 7.29372L3.68435 6.56186C4.03766 6.20855 4.64334 6.20855 4.99665 6.56186L8.2017 9.79214Z" fill="white"/>
      <path d="M11.0031 4.21469C11.3564 3.86138 11.9621 3.86138 12.3154 4.21469L13.0472 4.94655C13.4005 5.29986 13.4005 5.90554 13.0472 6.25885L7.49518 11.7857C7.14187 12.139 6.53619 12.139 6.18288 11.7857L5.45102 11.0538C5.09771 10.7005 5.09771 10.0948 5.45102 9.7415L11.0031 4.21469Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip093712">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconChecked",
};
</script>
