<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#F15249"/>
    <path d="M4.64355 11.609L8.95901 15.9245C12.5931 15.4954 15.4701 12.6437 15.9243 9.00964L11.5836 4.66895L4.64355 11.609Z" fill="#AD0E0E"/>
    <path d="M11.7852 9.76659C12.1385 10.1199 12.1385 10.7256 11.7852 11.0789L11.0786 11.7855C10.7252 12.1388 10.1196 12.1388 9.76625 11.7855L4.2142 6.23346C3.86089 5.88015 3.86089 5.27447 4.2142 4.92116L4.94606 4.1893C5.29938 3.83599 5.90505 3.83599 6.25837 4.1893L11.7852 9.76659Z" fill="white"/>
    <path d="M8.85813 6.81396L6.81396 8.85813L9.76664 11.7856C10.12 12.1389 10.7256 12.1389 11.0789 11.7856L11.8108 11.0537C12.1641 10.7004 12.1641 10.0947 11.8108 9.74141L8.85813 6.81396Z" fill="#D6D6D6"/>
    <path d="M9.76625 4.2142C10.1196 3.86089 10.7252 3.86089 11.0786 4.2142L11.8104 4.94606C12.1637 5.29938 12.1637 5.90505 11.8104 6.25837L6.23313 11.7852C5.87982 12.1385 5.27414 12.1385 4.92083 11.7852L4.2142 11.0786C3.86089 10.7252 3.86089 10.1196 4.2142 9.76625L9.76625 4.2142Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconNotChecked",
};
</script>
